import { useRef } from 'react';
import anime from 'animejs';
import { faDesktop } from '@fortawesome/free-solid-svg-icons'

import { If, Slot, createComponent, toClassName } from '@lib/util/templateHelpers';
import rating from '@lib/util/rating';
import { useClone } from '@lib/hooks/clone';
import { isMobileBreakpoint } from '@lib/util/mobile';
import shared from '@lib/components';
const { 
  GameTile,
  Flex,
  Icon
} = shared;

import styles from './index.module.scss';

const gameTileStates = [
  'featured'
];

export default createComponent('IoGameTile', { classStates: gameTileStates, styles }, function IoGameTile ({ className, style }, props) {
  const gameRating = props.game.rating && rating(props.game.rating) || '—';

  const ref = useRef(null);
  const debounceRef = useRef(null);
  const { clone, attach, remove } = useClone(ref);

  const mouseEnter = () => {
    if (isMobileBreakpoint()) return;
    
    clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      const brect = ref.current.getBoundingClientRect();
      const closestEl = ref.current.closest('.CarouselBlock__ContentInner, .GameTileBlock__ContentInner');
      if (!closestEl) return;
      
      const closestBrect = closestEl.getBoundingClientRect();

      const cloneEl = clone();
      cloneEl.classList.add('IoGameTile--floating');
      cloneEl.classList.add('IoGameTile--hideDesc');
      cloneEl.style.left = `${brect.left - closestBrect.left}px`;
      cloneEl.style.top = `${brect.top - closestBrect.top}px`;

      cloneEl.addEventListener('mouseleave', async () => {
        const cloneVideoEl = cloneEl.querySelector('video');
        if (cloneVideoEl) cloneVideoEl.classList.add('--hide');

        cloneEl.classList.remove('IoGameTile--hideDesc');

        await anime({
          targets: cloneEl,
          scale: 1
        }).finished;

        remove();
      });

      const videoEl = ref.current.querySelector('video');
      if (videoEl) {
        const cloneVideoEl = cloneEl.querySelector('video');
        if (!cloneVideoEl) return;

        cloneVideoEl.muted = true;
        cloneVideoEl.currentTime = 0;
        cloneVideoEl.play();
      }

      attach(ref.current.closest('.CarouselBlock__ContentInner, .GameTileBlock__ContentInner'));
      anime({
        targets: cloneEl,
        scale: 1.1
      });

    }, 500);
  };

  const mouseLeave = () => {
    clearTimeout(debounceRef.current);
  };

  const thumbnailUrl = props.featured && props.game.promoUrl
    ? props.game.promoUrl
    : props.game.thumbnailUrl;

  return (
    <GameTile
      innerRef={ref}
      className={className} 
      href={props.game.path}
      id={props.id}
      prefetch={false}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <Slot name='status'>
        {
          If(!props.game.supportsMobile, () => (
            <span className='IoGameTile__noMobile'>
              <span><Icon.FA icon={faDesktop} /> Desktop Only</span>
            </span>
          ))
          .EndIf()
        }
      </Slot>
      <Slot name='preview'>
        {
          If(props.game.videoThumbnailUrl, () => (
            <GameTile.VideoThumbnail 
              src={[ props.game.videoThumbnailUrl ]} 
              alt={props.game.title}
              poster={thumbnailUrl}
              priority={props.priority}
            />
          ))
          .ElseIf(props.game.thumbnailUrl, () => (
            <GameTile.Thumbnail 
              src={thumbnailUrl} 
              alt={props.game.title}
              priority={props.priority}
            />
          ))
          .EndIf()
        }
      </Slot>
      <Slot name='description'>
        <GameTile.Description>
          <Flex alignEnd>
            <Flex className='IoGameTile__Title'>{props.game.title}</Flex>
            <Flex pullRight className={toClassName('IoGameTile__Rating', { good: Number(gameRating) >= 4.0 })}>{gameRating}</Flex>
          </Flex>
        </GameTile.Description>
      </Slot>
    </GameTile>
  );
});
