import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { useResize } from '@lib/hooks/resize';

import { useMobileMenuStore, setCollapsed as setCollapsedMobile } from '@/stores/mobileMenu';
import { useMenuStore, setCollapsed } from '@/stores/menu';

export function useSidebar () {
  const router = useRouter();

  const [ mobileSidebarState, mobileDispatch ] = useMobileMenuStore();
  const [ sidebarState, menuDispatch ] = useMenuStore();

  const bodyScrollingRef = useRef(null);

  const toggleBodyScrolling = (shouldScroll) => {
    if (shouldScroll) {
      if (bodyScrollingRef.current) {
        document.body.style.overflowY = bodyScrollingRef.current;
      }
    } else {
      bodyScrollingRef.current = document.body.style.overflowY;
      document.body.style.overflowY = 'hidden';
    }
  };

  const isWidescreen = () => {
    return window.innerWidth >= process.env.APP_CONFIG.BREAKPOINTS.widescreen;
  };

  const collapse = () => {
    if (!isWidescreen()) {
      mobileDispatch(setCollapsedMobile, true);
      toggleBodyScrolling(true);
    } else {
      menuDispatch(setCollapsed, true);
    }
  };

  const expand = () => {
    if (!isWidescreen()) {
      mobileDispatch(setCollapsedMobile, false);
      toggleBodyScrolling(false);
    } else {
      menuDispatch(setCollapsed, false);
    }
  };

  useEffect(() => {
    const handleRouteChangeStart = () => {
      mobileDispatch(setCollapsedMobile, true);
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    }
  }, []);

  useResize(() => {
    mobileDispatch(setCollapsedMobile, true);
    toggleBodyScrolling(true);
  });

  return {
    expand,
    collapse,
    sidebarState,
    mobileSidebarState,
    isWidescreen
  }
}
