import { useRef } from 'react';

export function useClone (ref) {
  const cloneRef = useRef(null);

  const remove = () => {
    const cloneEl = cloneRef.current;
    if (!cloneEl.parentNode) return;

    cloneEl.parentNode.removeChild(cloneEl);

    return cloneEl;
  };

  const attach = (el) => {
    const cloneEl = cloneRef.current;
    el.appendChild(cloneEl);

    return cloneEl;
  };

  const clone = (attachEl) => {
    if (cloneRef.current) remove();

    const el = ref.current;
    const cloneEl = el.cloneNode(true);
    cloneRef.current = cloneEl;

    if (attachEl) attach(attachEl);

    return cloneEl;
  };

  return {
    clone,
    attach,
    remove
  }
}
