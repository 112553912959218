import { useRouter } from 'next/router'

import { If, toClassName, createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
const { Flex, Button, Icon, RouterLink } = shared;

import styles from './index.module.scss';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useMobileMenuStore, setCollapsed } from '@/stores/mobileMenu';

const MenuList = createComponent('MenuList', { styles }, function MenuList ({ className, slots }, props) {
  const collapse = () => {
    props.onCollapse();
  };

  return (
    <div className={className}>
      {slots.defaultSlot}
    </div>
  );
});
export default MenuList;

MenuList.Item = createComponent('MenuListItem', {}, function MenuListItem ({ mergeClassNames }, props) {
  const router = useRouter();
  const [ mobileState, mobileDispatch ] = useMobileMenuStore();

  const className = mergeClassNames(
    'Flex', 
    toClassName('MenuListItem', { active: router.pathname === props.href })
  );

  return (
    <RouterLink href={props.href} className={className} onClick={() => mobileDispatch(setCollapsed, true)}>
      {props.children}
    </RouterLink>
  );
});

MenuList.Overlay = createComponent('MenuListOverlay', {}, function MenuListOverlay ({ className }, props) {
  return (
    <div className={className}>
      {props.children}
    </div>
  );
});
