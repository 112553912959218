import { useState, useEffect } from 'react';

import { createComponent, For } from '@lib/util/templateHelpers';

import categoryApi from '@/apis/drupal/category';
import MenuList from '@/components/MenuList';

export default createComponent('CategoryMenuList', {}, function CategoryMenuList ({}, props) {
  const [ categories, setCategories ] = useState(props.categories || []);

  if (!props.categories) {
    useEffect(() => {
      (async () => {
        const categories = await categoryApi.listAll();
        setCategories(categories);
      })();
    }, []);
  }

  return (
    <MenuList>
      <MenuList.Item href='/top-rated'>Top</MenuList.Item>
      <MenuList.Item href='/popular'>Popular</MenuList.Item>
      <MenuList.Item href='/new'>New</MenuList.Item>
      <MenuList.Item href='/featured'>Featured</MenuList.Item>
      {
        For(categories, (category) => (
          <MenuList.Item key={category.id} href={category.path}>{category.name}</MenuList.Item>
        ))
      }
    </MenuList>
  );
});
