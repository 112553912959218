import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

import { createComponent } from '@lib/util/templateHelpers';
import shared from '@lib/components';
const { Flex, Icon, Button } = shared;

import styles from './index.module.scss';

const expandButtonClassStates = [
  'collapsed'
];

export const SidebarButtonExpand = createComponent('SidebarButtonExpand', { styles, classStates: expandButtonClassStates }, function SidebarButtonExpand ({ mergeClassNames }, props) {
  const className = mergeClassNames('SidebarButton');

  return (
    <Button primary className={className} onClick={props.onClick} ariaLabel='Expand Categories'>
      <Icon.FA icon={faArrowCircleRight} />
    </Button>
  );
});

export const SidebarButtonCollapse = createComponent('SidebarButtonCollapse', { styles }, function SidebarButtonCollapse ({ mergeClassNames }, props) {
  const className = mergeClassNames('SidebarButton');

  return (
    <Button primary className={className} onClick={props.onClick} ariaLabel='Collapse Categories'>
      <Flex inline container gap0 tag='span' alignCenter>
        <Flex tag='span'><Icon.FA icon={faArrowCircleLeft} /></Flex>
        <Flex tag='span'>{props.children}</Flex>
      </Flex>
    </Button>
  );
});
