import { useEffect } from 'react';
import { If, createComponent, toClassName, Slot } from '@lib/util/templateHelpers';
import shared from '@lib/components';
const { App, Layout, Container, Flex, Block } = shared;

import { useSidebar } from '@/hooks/sidebar';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import MenuList from '@/components/MenuList';
import { SidebarButtonCollapse, SidebarButtonExpand } from '@/components/SidebarButton';

import styles from './index.module.scss';

export default createComponent('CollapsableSidebarLayout', { styles }, function CollapsableSidebarLayout ({ mergeClassNames, slots }, props) {
  const {
    expand, 
    collapse, 
    sidebarState,
    mobileSidebarState,
    isWidescreen
  } = useSidebar();

  const className = mergeClassNames(toClassName('CollapsableSidebarLayout', {
    sidebarCollapsed: sidebarState.collapsed,
    mobileSidebarCollapsed: mobileSidebarState.collapsed,
    mobileOnly: props.mobileOnly
  }));

  useEffect(() => {
    if (props.mobileOnly && sidebarState.collapsed && isWidescreen()) {
      expand();
    }
  }, []);

  return (
    <Layout className={className}>
      <MenuList.Overlay className='CollapsableSidebarLayout__MobileSidebar'>
        <Block>
          <SidebarButtonCollapse onClick={collapse}>
            {slots.sidebarHeader}
          </SidebarButtonCollapse>
        </Block>
        <Block>
          {slots.sidebar}
        </Block>
      </MenuList.Overlay>

      <App.Header>
        <AppHeader>
          <Slot name='mobile'>
            <SidebarButtonExpand onClick={expand} collapsed={!mobileSidebarState.collapsed} />
          </Slot>
          <Slot name='desktop'>
            {
              If(!props.mobileOnly, () => (
                <SidebarButtonExpand onClick={expand} collapsed={!sidebarState.collapsed} />
              )).EndIf()
            }
          </Slot>
        </AppHeader>
      </App.Header>

      <App.Main>
        <Container wide>
          <Flex container gap2>
            <Flex tag='aside' className='CollapsableSidebarLayout__Sidebar'>
              <div>
                {
                  If(!props.mobileOnly, () => (
                    <Block>
                      <SidebarButtonCollapse onClick={collapse}>
                        {slots.sidebarHeader}
                      </SidebarButtonCollapse>
                    </Block>
                  )).EndIf()
                }
                <Block>
                  {slots.sidebar}
                </Block>
              </div>
            </Flex>
            <Flex fit directionColumn style={{minWidth: 0}}>
              <div>
                {slots.defaultSlot}
              </div>
            </Flex>
          </Flex>
        </Container>
      </App.Main>

      <App.Footer>
        <AppFooter hasBannerAd={props.hasBannerAd} />
      </App.Footer>
    </Layout>
  )
});
