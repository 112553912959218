import { createNetworkApi, toQuery } from '@lib/util/networkRequest';

import apiInstance from '../instances/drupal';
import { formatCategory } from '@/util/formatters';

const categoryQuery = {
  fields: {
    'taxonomy_term--category': [
      'id', 
      'name', 
      'drupal_internal__tid',
      'description', 
      'field_thumbnail', 
      'path', 
      'weight'
    ]
  },
  include: [
    'field_thumbnail'
  ]
};

export default createNetworkApi (apiInstance, {
  getById: (categoryId) => ({
    method: 'get',
    url: toQuery(`/jsonapi/taxonomy_term/category/${categoryId}`, {
        ...categoryQuery,
        filter: {
          status: 1
        }
      }),
    transform: (result) => formatCategory(result.data, result.included)
  }),

  listAll: (limit = 50, offset = 0) => ({
    method: 'get',
    url: toQuery('/jsonapi/taxonomy_term/category', {
        ...categoryQuery,
        filter: {
          status: 1
        },
        sort: '-weight',
        page: {
          limit,
          offset
        }
      }),
    transform: (result) => result.data.map((category) => formatCategory(category, result.included))
  })
})
